<template>
    <div style="display:flex; flex-direction: column; align-items: flex-start;">
        <div style="width:100%;"> 
            <v-checkbox
                v-for=" (checklistOptions, index) in componentChecklist"
                :key="checklistOptions.value"
                :index="index"
                :value="checklistOptions.value"  
                :label="checklistOptions.display.en" 
                v-model="checkList"
                :disabled="checklistOptions.disabled || checklistUpdateDisable"
                multiple
                hide-details
                @change="checklistData"
                :class="index==componentChecklist.length-1?'lastcheckBoxClass':'checkBoxClass'"
            ></v-checkbox>
        </div>
        <div 
            style="
                margin:10px 0px 10px 0px; 
                padding-bottom: 10px;
                width:100%; 
                border-bottom:1px solid #D5DADE; 
                display:flex; color:blue; 
                flex-direction: row; " 
                v-if="showAddChecklistsection">
                <v-text-field
                    label="Enter The Value"
                    background-color="#F2F2F2" 
                    filled
                    persistent-hint
                    outlined
                    flat 
                    solo
                    @keyup.enter="saveCheckListOption()"
                    @click:append="saveCheckListOption()"
                    v-model="valueData" 
                    style="width:90%;"
                    hide-details="auto"
                    :error-messages="showEmptyListMessage ? ['Please Input List Value'] : '' "
                    >
                    <template #append>
                        <p style="margin:10px; display:flex; align-items: center; color:gray; font-size:0.7rem;">Press "Enter" to submit</p>
                        <v-btn @click="saveCheckListOption()" style="background-color:#2A409A; color:white;" > Submit </v-btn>
                    </template>
                    <v-icon @click="closeChecklistInput()" style="color:red; width:7%; height:55px;">mdi-close</v-icon>
                </v-text-field> 
            </div>
            <div v-if="duplicateChecklistShowError">
                <span class="error-message" style="margin:10px; display:flex; align-items: center; color:rgb(244, 13, 13); font-size:0.9rem;">
                   {{ duplicateChecklistShowErrormessage }}
                </span> 
            </div>
            <v-btn depressed style="margin:10px 10px 10px 0px;" v-if="allowAddChecklist" v-bind:class="[addBtnDIsabled ? 'displayNone' : '' ]" :disabled="addBtnDIsabled" @click="addCheckListOption" color="white"> 
                <v-icon left color="primary"> mdi-plus-box-outline </v-icon> 
                <span>  Add Option </span>
        </v-btn>
        
    </div>
</template>

<script>

export default {
    props:['checklistUpdateDisable', 'value','ChecklistOptionsData', 'addAllowBtn'],
    data(){
        return{
            componentChecklist:[],
            checkList:[], 
            showAddChecklistsection:false,
            allowAddChecklist:false,
            valueData:'',
            addBtnDIsabled:false,
            showEmptyListMessage:false,
            hasChecklistOptions: false,
            duplicateChecklistShowError : false,
            duplicateChecklistShowErrormessage:'',
        } 
    },
    computed:{
        checklistArray(){
            let options = this.componentChecklist.map(option => {
                if(this.checkList.includes(option.value)){
                    return {
                        'checked': true,
                        'value': option.value, 
                        'display': option.display,
                    }
                } else {
                    return {
                        'checked': false,
                        'value': option.value, 
                        'display': option.display,
                    }
                }
            })
            return options;
        }
    },
    methods:{
        addCheckListOption(){
            this.showAddChecklistsection = true; 
            this.allowAddChecklist = false;
        },
        duplicateOptionError(){
            this.duplicateChecklistShowError = true;
            this.duplicateChecklistShowErrormessage = 'Error, Option value already Exists';
        },
        saveCheckListOption(){  
            if(this.valueData){
                var newInputedCheckoption = {
                    'disabled' : false,
                    'preselected' : false,
                    'value': this.valueData,
                    'display':{
                        "mm":this.valueData,
                        "en":this.valueData
                    },
                };
                for(let item in this.checklistArray){
                     if(newInputedCheckoption.value === this.checklistArray[item].value) {
                        this.hasChecklistOptions = true;
                    }   
                }
                if(!this.hasChecklistOptions){
                this.componentChecklist.push(newInputedCheckoption);
                this.valueData =''; 
                this.showAddChecklistsection = false;
                this.checklistData();
                this.allowAddChecklist = true;
                this.duplicateChecklistShowError = false;
            }
            else{
                this.duplicateOptionError();
                this.hasChecklistOptions = false;
            }
        } else {
                this.showEmptyListMessage = true;
            }
        },
        checklistData(){
            this.$emit("input",  this.checklistArray); 
        },
        closeChecklistInput(){
            this.showAddChecklistsection = false
            this.allowAddChecklist = true
        }
    },
    mounted(){ 
        this.componentChecklist = this.ChecklistOptionsData
        const checkListValus = this.componentChecklist.map(function(element){
            return element.value
        })
        if(this.addAllowBtn.isDisabled === true || this.checklistUpdateDisable === true){ 
            this.allowAddChecklist = false
            this.addBtnDIsabled = true
            this.componentChecklist.forEach(elementDataSet => {
                elementDataSet.disabled= true
            }) 
        }
        if(this.value){
            this.value.forEach(element => {
                if(!checkListValus.includes(element.value)){
                    let checklistObject = {
                        'disabled' : false,
                        'preselected' : false,
                        'value':element.value,
                        'display':{
                            "mm":element.value,
                            "en":element.value,
                        },
                    } 
                    if(element.checked) {
                        checklistObject.preselected = true
                    }
                    this.componentChecklist.push(checklistObject)
                } else if(element.checked) {
                    this.checkList.push(element.value) 
                }
            })
        }
        this.componentChecklist.forEach(element => { 
            if(element.hasOwnProperty('preselected') && element.preselected === true){
                this.checkList.push(element.value) 
            }
            this.checklistData();
        })
        if(this.addAllowBtn.hasOwnProperty('allowUserDefinedValues') && this.addAllowBtn.allowUserDefinedValues === true){
            this.allowAddChecklist = true;
        }
    }
}
</script>

<style scoped>
    .displayNone{
        display:none;
    }
    .checkBoxClass{
        border-bottom:1px solid #D5DADE; 
        width:100%; 
        padding:0px 0px 10px 0px;
        margin-top: 10px;
    }
    .lastcheckBoxClass{
        border-bottom:none; 
        width:100%; 
        padding:0px 0px 10px 0px;
        margin-top: 10px;
    }
</style>