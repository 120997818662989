<template class="input_field_div">
    <v-text-field :disabled="isDisable" outlined background-color="#F2F2F2" @change="displayFormatter()"
        v-model="numberInputValue"></v-text-field>
</template>
<script>
export default {
    props: ["value", "updateAccess", "numberConfiguration"],
    data() {
        return {
            numberInputValue: "",
            formatConfig: {
                maximumFractionDigits: 20
            },
            isDisable: false
        };
    },
    watch:{
		value(newValue) {
			this.numberInputValue = newValue;
            if(this.numberInputValue){
                this.displayFormatter();
            }
		}
    },
    methods: {
        displayFormatter() {
            this.numberInputValue = String(this.numberInputValue).replaceAll(",", "")
            this.$emit("input", Number(this.numberInputValue));
            this.intlFormatter()
        },
        intlFormatter() {
            if (!isNaN(this.numberInputValue)) {
                if (this.numberConfiguration && this.numberConfiguration.numberSystem) {
                    if (this.numberConfiguration.numberSystem == "English") {
                        const NumberFormatter = new Intl.NumberFormat("en-US", this.formatConfig);
                        this.numberInputValue = NumberFormatter.format(this.numberInputValue);
                    }
                    else if (this.numberConfiguration.numberSystem == "Arabic") {
                        const NumberFormatter = new Intl.NumberFormat("en-AR", this.formatConfig);
                        this.numberInputValue = NumberFormatter.format(this.numberInputValue);
                    }
                }
            }

        }
    },
    mounted() {
        this.numberInputValue = this.value;
        this.isDisable = this.updateAccess;
        if (this.numberInputValue)
            this.intlFormatter()
    },
};
</script>
  
<style>
.input_field_div {
    /* width: 600px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 1px;
    color: #4f4f4f;
    /* border: 1px solid;
    padding: 8px;
    margin-top: auto;
    margin-bottom: auto; */
}
</style>
  