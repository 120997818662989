<template>
  <v-tooltip right >
    <template v-slot:activator="{ on, attrs }"  >
      <v-icon  transition="fade-transition"
        v-bind="attrs"
        v-on="on" 
          style="font-size: 15px;"> mdi-help-circle 
      </v-icon>
    </template>
    <span >{{ToolMessage}}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: ["ToolMessage"],
  data() {
    return {
    };
  }
}
</script>

<style scoped>

.v-tooltip__content {
  background-color: #F2F2F2 !important;
  border: 1px solid #e0e0e0 !important;
  color: black !important;
  font-family: Poppins !important;
  max-width: 50% !important;
}
</style>