<template>
    <div class="datetimePicker">
        <div class="datePicker">
            <v-datetime-picker 
                v-model="dateTime" 
                time-format = "HH:mm:ss"
                use-seconds = true
                date-format = "dd-MM-yyyy"
                @input="emitDate" 
                :date-picker-props="dateProps"
                :disabled = "this.$props.disable"
            >
                <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                    <v-icon>mdi-clock-outline</v-icon>
                </template>
            </v-datetime-picker>
        </div>
        <div class="timepickercomp">
            <div class="timezonecomp">Time zone</div>
            <div class="v-btn-timezone" disabled>{{this.timezone}}</div>
        </div>
    </div>
      
</template>

<script>
import DatetimePicker from 'vuetify-datetime-picker'
import moment from "moment-timezone";

export default {
    props: ['dateTimeDetails','value', 'min', 'max', 'disable', 'tzConfig'],
    components: {
        DatetimePicker
    },
    data() {
        return {
            dateTime: null,
            timezone: '',
            epochDateTime: 0,
            dateProps: {},
        };
    },
    watch: {  
        min: function(newValue, oldValue) {
            if(newValue){
                this.dateProps.min = moment.unix(newValue).tz(this.timezone).format("YYYY-MM-DD");
            }else {
                delete this.dateProps['min'];
            }
        },
        max: function(newValue, oldValue) {
            if(newValue){
                this.dateProps.max = moment.unix(newValue).tz(this.timezone).format("YYYY-MM-DD");
            }else {
                delete this.dateProps['max'];
            }
        },
    },
    methods:{
     emitDate(){
        if(this.dateTime) {
            this.epochDateTime = moment.tz(moment(this.dateTime).format("DD-MM-YYYY HH:mm:ss"),"DD-MM-YYYY HH:mm:ss",this.timezone).unix();
            this.$emit("input", this.epochDateTime);
        }
        else{
            this.$emit("input", null);
        }
        
     },
    },
    mounted() {
        this.timezone = this.$props.tzConfig.timezone;
        if(this.$props.min && this.$props.min != 'Invalid date'){ this.dateProps.min = moment.unix(this.$props.min).tz(this.timezone).format("YYYY-MM-DD");}
        if(this.$props.max && this.$props.max != 'Invalid date'){ this.dateProps.max = moment.unix(this.$props.max).tz(this.timezone).format("YYYY-MM-DD");}
        if(this.$props.value){ 
            if(!((this.dateProps.min && Number(this.dateProps.min) > this.dateTime) ||
                (this.dateProps.max && Number(this.dateProps.max) < this.dateTime))){
                    this.dateTime = moment.unix(this.$props.value).tz(this.timezone).format("DD-MM-YYYY HH:mm:ss");
            }
        }
    }
}
</script>

<style scoped>
.datetimePicker{
    margin-bottom:30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.datePicker{
    border:1px solid gray;  
    border-radius:5px; 
    background-color:#f0f0f0; 
    height:60px; 
    width:60%;
    padding-left: 0.65rem;
}

.timePicker{
    margin-left: 5px;
    height:3.5rem; 
    background-color:"#F2F2F2";
}

.timepickercomp{
  margin-top: -1.4rem;
}

.v-btn-timezone{
    font-size: 1rem;
    padding-left: 10px; 
    padding-right: 10px; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    border:1px solid gray; 
    border-radius:5px; 
    background-color:#f0f0f0; 
    height:60px; 
    width:100%; 
    color: gray;
}

.timezonecomp{
    margin:0rem 4rem 0rem 0rem;
    padding-top:-10px; 
    margin-top: -10px; 
    margin-bottom: 10px;
}

</style>
  

