<template>
  <div>
    <div>
      <VuePhoneNumberInput :defaultCountryCode="!phoneCountrycode ? 'MM' : phoneCountrycode" @update="showPayload" :disabled="disabledField"
        v-model="phonenumber" />
    </div>
  </div>
</template>
<script>
import VuePhoneNumberInput from "vue-phone-number-input";
export default {
  props: ['phonenumberDetail', 'value', 'additionalFieldInformation', 'disabled'],
  components: {
    VuePhoneNumberInput
  },
  data() {
    return {
      phonenumber: '',
      countryCode: '',
      phone: '',
      phoneCountrycode: '',
      phoneCountryDetail: {},
      fieldKey: '',
      disabledField:this.disabled,
    }
  },
  methods: {
    showPayload(payload) {
      this.phone = payload.formatInternational
      this.phoneCountryDetail['callingCode'] = payload.countryCallingCode
      this.phoneCountryDetail['countryCode'] = payload.countryCode
      this.phoneDetailEmit();
    },
    phoneDetailEmit() {
      this.$emit("input", this.phone);
      this.$emit("additionalField", this.phoneCountryDetail);
    },
  },
  mounted() {
    if(this.additionalFieldInformation && this.additionalFieldInformation.countryCode){
      this.phoneCountrycode = this.additionalFieldInformation.countryCode
    }
    else if(this.phonenumberDetail.phonenumberConfiguration && this.phonenumberDetail.phonenumberConfiguration.defaultCountryCode) {
      this.phoneCountrycode = this.phonenumberDetail.phonenumberConfiguration.defaultCountryCode;
    }
    this.phonenumber = this.value    
  }
}
</script>