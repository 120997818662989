export const formatFileNameMixin = {
    inheritAttrs: false,
    methods: {
        fileNameFormat(text) {
            const suffix = ['-copy', '- Copy', '-copy (', '- Copy ('];
            const maxLength = 20;
            // Check if the text has a suffix
            for (let i = 0; i < suffix.length; i++) {
              const index = text.lastIndexOf(suffix[i]);
              if (index !== -1 && index + suffix[i].length === text.length) {
                // If the suffix is at the end of the string, truncate before it
                text = text.slice(0, index);
                break;
              }
            }
            // Check if the text has an extension
            const extIndex = text.lastIndexOf('.');
            if (extIndex !== -1) {
              const ext = text.slice(extIndex);
              const baseName = text.slice(0, extIndex);
              // If the base name is too long, truncate it and add ellipsis and extension
              if (baseName.length > maxLength) {
                const baseNameTruncated = baseName.slice(0, maxLength - ext.length - 1);
                return `${baseNameTruncated}...${ext}`;
              } else {
                // If the base name is short enough, add the extension back to the string
                return `${baseName}${ext}`;
              }
            } else {
              // If there is no extension, just truncate the string
              if (text.length > maxLength) {
                return `${text.slice(0, maxLength)}...`;
              } else {
                return text;
              }
            }
        }
    }
}